import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react'
import { config, useTransition } from 'react-spring';

import AppState, { SCREENS } from '../../js/AppState';
import Quiz, {RESULT} from '../../js/Quiz';
import Confettis from '../Confettis/Confettis';
import Personnage from '../Personnage/Personnage';
import Header from '../shared/Header/Header';

import QuizAnswers from './QuizAnswers/QuizAnswers';
import QuizDebrief from './QuizDebrief/QuizDebrief';
import QuizDuration from './QuizDuration/QuizDuration';
import QuizImage from './QuizImage/QuizImage';
import QuizQuestionCount from './QuizQuestionCount/QuizQuestionCount';

import './ScreenQuiz.css'

/* steps

0 : numéro de question
1 : texte question + image
2 : texte question + image + choix
3 : texte question + image + choix validés
4 : texte question + image + choix validés + feedback
*/
const getResult = (answered, q) => {
  if(!answered) return false

  if(!q.getLastAnswer()) return RESULT.TIMEOUT

  if(q.getLastAnswer().raw.correct_answer) return RESULT.CORRECT

  return RESULT.WRONG

}

const ScreenQuiz = () => {
  const [answered, setAnswered] = useState(null);
  const [showPersonnage, setShowPersonnage] = useState(false);

  const [step, setStep] = useState(0);

  const screenRef = useRef(null)

  let q = Quiz.sequence.getCurrentElement() // le currentElement est TOUJOURS un choice_title maintenant

  let answer_result = getResult(answered, q)


  function handleChoiceClick(choice) {
    if(answered) return
    if(q.getLastAnswerId()) return // au cas ou le state est pas déja active, si on multiclique très vite (?)

    q.addAnswer(choice.id)
    answer()
  }

  function answer() {
    setAnswered(true)
    if(step === 2) {
      let answer_result = getResult(true, q)

      console.log("answer_result", answer_result)
      if(answer_result === RESULT.CORRECT) Quiz.incrementScore()

      Quiz.saveProgress(true)
      // console.log("answer 2 je passe à step = 3")
      setStep(3)
    }
    setTimeout(() => setShowPersonnage(true), 500)
  }

  // après le feedback du perso "bravo bien joué!"
  function feedbackNext() {
    setShowPersonnage(false)
    if(step === 3) {
      // console.log("feedbackNext 3 je passe à step = 4")
      setStep(4)
    }
  }

  function debriefNext() {
    if(!answered) return
    setAnswered(false)

    setShowPersonnage(false)
    Quiz.sequenceNext()


    if(step === 4) {
      // console.log("debriefNext 4 je passe à step = 0")
      setStep(0)
    }
  }





  useEffect(() => {
    if(answered) {
      if(answer_result === RESULT.TIMEOUT) {
        Quiz.nb_timeouts = Math.min(Quiz.nb_timeouts+1, 4) // seulement 4 repliques de timeout
      }
    }

    // TODO pourquoi ne pas mettre ca dans debriefNext ?
    // reset à false après le clic sur le bouton suivant après le debrief
    if(answered === false) {
      // après la 4eme question, on affiche un interlude
      if(Quiz.current_question === 5) {
        AppState.setScreenPerso(null, Quiz.pickInterludeSequenceId(), () => AppState.setScreen(SCREENS.QUIZ))
      }

      // après la 7eme question, on affiche un interlude
      if(Quiz.current_question === 8) {
        AppState.setScreenPerso(null, Quiz.pickInterludeSequenceId(), () => AppState.setScreen(SCREENS.QUIZ))
      }
    }
  }, [answered])


  // a chaque step, on scroll en bas au max
  useEffect(() => {
    let to = setTimeout(() => {
      screenRef.current.scroll({behavior: "smooth", top: screenRef.current.offsetHeight});
    }, 10) // TODO à voir si ce timeout permet de scroll au max sur les téléphones
    return () => {
      clearTimeout(to)
    }
  }, [step]);


  // passage automatique des steps 0 et 1 (question num et image seule)
  useEffect(() => {
    const {questionNum, image} = window.CONFIG.timeouts
    if(step === 0) {
      let to = setTimeout(() => setStep(1), questionNum)
      return () => clearTimeout(to)
    }

    if(step === 1) {
      let to = setTimeout(() => setStep(2), image)
      return () => clearTimeout(to)
    }


  }, [step]);

  return (
    <div className="ScreenQuiz">
      <Header>
        <div className='quiz-header'>
          {step > 0 && q && <div className='question_text' title={q.id}>{q.raw.Text}</div>}
        </div>
      </Header>



      <div className="ScreenQuiz-content"  ref={screenRef}>

        {step === 0 &&  <QuizQuestionCount />}

        <div style={{textAlign: "center"}}>
          {step >= 1 && <QuizImage src={`./data/images/${q.SequenceId}.jpg`} />}
          {step >= 2 &&
          <>
            <QuizDuration q={q} onTimeElapsed={answer} answered={answered} />
            <QuizAnswers key={q.id} choices={q.choices} answered={answered} answerId={q.getLastAnswerId()} onAnswer={handleChoiceClick} />
          </>}
        </div>

        {showPersonnage &&
        <Personnage sequenceId={Quiz.pickFeedbackSequenceId(answer_result)} onSequenceEnd={feedbackNext}/>}


        {showPersonnage && window.CONFIG.confettis && <Confettis success={answer_result === RESULT.CORRECT} />}

        {step === 4 &&
        <QuizDebrief answer_result={answer_result} text={q.raw.Debrief} onNext={debriefNext} />}

      </div>
    </div>
  );
};

export default observer(ScreenQuiz)
