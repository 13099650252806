import React from 'react'
import white_logo from "../../../images/white_logo.png"
import './Baseline.css'
import PopupsManager from "react-sdk/PopupsManager.js"
import { POPUPS } from '../../../js/AppState';
import Texts from 'react-sdk/Texts';
const Baseline = () => {

  function handleReglementClick(e) {
    e.preventDefault()

    PopupsManager.open(POPUPS.REGLEMENT, {title: Texts.get("reglement-popup-title"), content: Texts.get("reglement-popup-content") })

  }

  return (
    <div className="Baseline">
      <div className="spacer" />

      <a onClick={handleReglementClick} href="#">Règlement du jeu concours</a>
      <div className="spacer" />
      <div>
        <img src={white_logo} />
        <span>Seriously made with fun by <a target='_blank' href="https://succubus.fr">succubus interactive</a></span>
      </div>
    </div>
  );
};

export default Baseline;
