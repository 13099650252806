import React, { useEffect, useState } from 'react'
import { useTrail, config, animated } from 'react-spring'

import './QuizAnswers.css'

const QuizAnswers = ({choices, answered, answerId, onAnswer}) => {

  const trail = useTrail(choices.length, {
    config: { tension: 300, friction: 26},
    x: "0%",
    opacity: 1,
    from: { x: "-100%", opacity: 0 },
   })

  const items = choices.map(c =>{
    let red = answered && c.id === answerId && !c.raw.correct_answer
    let green = answered && c.raw.correct_answer

    let className = "choice"
    className += red ? " red" : ""
    className += green ? " green" : ""
    return <button key={c.id} className={className}  onClick={() => onAnswer(c)}>{c.raw.Text}</button>
  })

  return (
    <div className="QuizAnswers">
      {trail.map((style, index) => {
        return <animated.div key={choices[index].id} style={style}>{items[index]}</animated.div>
      })}
    </div>
  );
};

export default QuizAnswers
