import React, { useRef, useState } from 'react';
import AppState, {SCREENS} from '../../../js/AppState';

export default ({onForgotClick}) => {
  const [error, setError] = useState(null);
  const formRef = useRef(null)

  function handleSubmit (e) {
    e.preventDefault()
    setError(null)

    const data = new FormData(formRef.current)

    const email = data.get('email')
    const password = data.get('password')
    const auto_reconnect = data.get('auto_reconnect') === "on"


    AppState.vertxApi.logout() // on se logout car on est connecté en anonymous normalement (au pire c pas grave ca fait rien)
    .then(() => AppState.vertxApi.loginWithCredentials(email, password, auto_reconnect) )
    .then(() => AppState.setScreen(SCREENS.HOME))
    .catch(err => setError(err))
  }

  function loginTier(provider) {

    AppState.vertxApi.logout() // on se logout car on est connecté en anonymous normalement (au pire c pas grave ca fait rien)
    .then(() => AppState.vertxApi.loginWithExternalProvider(provider))
    .then(() => AppState.setScreen(SCREENS.HOME))
    .catch(err => setError(err))
  }

  let errorstring = ""


  if(error) {
    if(error.error_description) errorstring = error.error_description
    else if (error.error) errorstring = error.error
    else if (typeof error === "string") errorstring = error
    else errorstring = JSON.stringify(error)
  }

  return (
  <div className='LoginForm'>

    <h3>Login</h3>
    <div className="LoginForm-columns">
      <div className="LoginForm-column">
        <form onSubmit={handleSubmit} ref={formRef}>
          <div>
            <input id="email" name="email" type="email" placeholder="Mail" required />
          </div>

          <div>
            <input id="password" name="password" type="password" placeholder="Password" required/>
          </div>
          <label>
            <input type="checkbox" name="auto_reconnect"/> Stay connected
          </label>
          <div><button>Submit</button></div>

        </form>
        <button className="link-btn" onClick={onForgotClick}>Password forgotten</button>
      </div>

      <div className="LoginForm-column">
        <button onClick={() => loginTier("google")} >Login with google</button>
      </div>
    </div>
    {error && <div className="error">{errorstring}</div>}

  </div>)
}