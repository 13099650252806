import { observer } from "mobx-react-lite";
import React from "react";
import AppState, { POPUPS, SCREENS } from "../../js/AppState";

import ScreenLoading from "../ScreenLoading/ScreenLoading";
import ScreenHome from "../ScreenHome/ScreenHome";

import './App.css'

import '../../fonts/stylesheet.css'


import ScreenLogin from "../ScreenLogin/ScreenLogin";
import ScreenForm from "../ScreenForm/ScreenForm";
import ScreenQuiz from "../ScreenQuiz/ScreenQuiz";
import ScreenEnd from "../ScreenEnd/ScreenEnd";

import ScreenPerso from "../ScreenPerso/ScreenPerso";
import Quiz from "../../js/Quiz";
import Notifications from "../shared/Notifications/Notifications";
import PopupsManager from "react-sdk/PopupsManager";
import Popup from "../shared/Popup/Popup";


const App = () => {
  return (
    <div className="App">
      {AppState.screen === SCREENS.LOADING && <ScreenLoading />}
      {AppState.screen === SCREENS.HOME && <ScreenHome />}
      {AppState.screen === SCREENS.CHAT && <ScreenChat />}
      {AppState.screen === SCREENS.LOGIN && <ScreenLogin />}
      {AppState.screen === SCREENS.FORM && <ScreenForm />}
      {AppState.screen === SCREENS.QUIZ && <ScreenQuiz />}
      {AppState.screen === SCREENS.PERSO && <ScreenPerso />}
      {AppState.screen === SCREENS.END && <ScreenEnd />}

      {PopupsManager.isOpened(POPUPS.REGLEMENT) && <Popup data={PopupsManager.getData(POPUPS.REGLEMENT)} />}


      {window.CONFIG.showNotifs && <Notifications />}

      {/* <div className="version">v{process.env.REACT_APP_VERSION}</div> */}
      {AppState.debugMode &&
      <div className="debug">
        Debug mode: {AppState.debugMode}
        <div>
          <button onClick={() => {
            localStorage.removeItem(Quiz.sequenceSaveLSKey)
            localStorage.removeItem(Quiz.scoresSaveLSKey)
          }}>delete LS</button>
        </div>

      </div>}
    </div>
  )
}

export default observer(App);
