import React, { useRef, useState } from 'react'
import Notifications from 'react-sdk/Notifications/Notifications';
import Texts from 'react-sdk/Texts';
import AppState, { SCREENS } from '../../js/AppState';
import Quiz from '../../js/Quiz';
import Personnage from '../Personnage/Personnage';
import Header from '../shared/Header/Header';
import StarsHeader from '../shared/StarsHeader/StarsHeader';

import './ScreenForm.css'

const ScreenForm = () => {
  const formRef = useRef(null)

  const [showPerso, setShowPerso] = useState(true);
  const [formValidity, setFormValidity] = useState(false);
  function handleSubmit(e) {
    e.preventDefault()

    const data = new FormData(formRef.current)

    const email = data.get('email')
    const nom = data.get('nom')
    const prenom = data.get('prenom')

    const entreprise = data.get('entreprise')
    const poste = data.get('poste')
    const consent = data.get('consent') === "on"

    // console.log("consent", consent)

    let pseudo = prenom + " " + nom

    let score = {
      nom, prenom,
      pseudo,
      score: Quiz.score,
      stars: Quiz.stars,
      question_nb: Quiz.question_total
    }

    AppState.vertxApi.userUpdateInfo(email, pseudo, nom, prenom)
    .then(() => AppState.vertxApi.userUpdateCustomDatas({entreprise, poste}))
    .then(() =>  {
      sendDataToHubspot(email, nom, prenom, entreprise, poste, consent, () => {
        console.log("form posted")
        AppState.vertxApi.meetingSendScores(score)
        .then(() => {

          AppState.setScreenPerso(null, "debriefing", () => {
            AppState.setScreenPerso(<StarsHeader />, Quiz.pickStarsSequenceId(), () => {
              AppState.setScreen(SCREENS.END)
            })
          })
          Quiz.deleteSave()
        })

      })
    })
    .catch(err => Notifications.error(err))




  }

  function sendDataToHubspot(email, nom, prenom, entreprise, poste, consent, cb){
    // Create the new request

    let {hubspotPortalId, hubspotFormId} = window.CONFIG

    var xhr = new XMLHttpRequest();
    var url = `https://api.hsforms.com/submissions/v3/integration/submit/${hubspotPortalId}/${hubspotFormId}`

    // Example request JSON:
    var data = {
      // "submittedAt": new Date().getTime(),
      "fields": [
        {
          "objectTypeId": "0-1",
          "name": "email",
          "value": email
        }
        ,{
          "objectTypeId": "0-1",
          "name": "firstname",
          "value": prenom
        }
        ,{
          "objectTypeId": "0-1",
          "name": "lastname",
          "value": nom
        }
        ,{
          "objectTypeId": "0-1",
          "name": "company",
          "value": entreprise
        }
        ,{
          "objectTypeId": "0-1",
          "name": "jobtitle",
          "value": poste
        }
      ],
      // "context": {
      //   "hutk": ":hutk", // include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission
      //   "pageUri": "quizlt.succubus.dev",
      //   "pageName": "Quiz LT2022"
      // },
      "legalConsentOptions":{ // Include this object when GDPR options are enabled
        "consent":{
          "consentToProcess":true,
          "text":"I agree to allow Example Company to store and process my personal data.",
          "communications":[
            {
              "value":consent,
              "subscriptionTypeId":999,
              "text":"I agree to receive marketing communications from Example Company."
            }
          ]
        }
      }
    }

    var final_data = JSON.stringify(data)

    xhr.open('POST', url);
    // Sets the value of the 'Content-Type' HTTP request headers to 'application/json'
    xhr.setRequestHeader('Content-Type', 'application/json');

    xhr.onreadystatechange = function() {
      if(xhr.readyState === 4){
        if(xhr.status !== 200) {
          Notifications.error(Texts.get("error_send_form"))
          console.log(xhr.responseText)
        }
        cb()
      }
    }

    xhr.send(final_data)
 }

  function handleChange(e) {
    let elems = Array.from(formRef.current.elements)
    let valid = elems.filter(e => e.name).every(e => e.validity.valid)

    setFormValidity(valid)
 }


 let sequenceId = "debriefing_1"




  return (
    <div className="ScreenForm">
      <Header>
        {/* <StarsHeader /> */}
      </Header>
      <div className="ScreenForm-content">

        <div className="hero">{Texts.get("form_title")}</div>

        <form onSubmit={handleSubmit} ref={formRef} onChange={handleChange}>
          <div>
            <input required type="text" name="prenom" placeholder='Prénom' />
            <input required type="text" name="nom" placeholder='Nom' />
          </div>
          <input required type="email" name="email" placeholder='Adresse mail professionnelle' />
          <input required type="text" name="entreprise" placeholder="Nom de l'entreprise" />
          <input required type="text" name="poste" placeholder='Intitulé de poste' />
          <div className="mentions-legales">
            <p> <label><input type="checkbox" name='consent'/>{Texts.get("form_consent")}</label></p>
            <p dangerouslySetInnerHTML={{__html: Texts.get("form_legal")}} />
          </div>
          <div className="mentions-legales">
            <p dangerouslySetInnerHTML={{__html: Texts.get("form_legal_2")}} />
          </div>
          <div>
            <button className={formValidity ? "valid" : ""}>{Texts.get("form_button")}</button>
          </div>
        </form>
      </div>

      {showPerso && <Personnage sequenceId={sequenceId} onSequenceEnd={() => setShowPerso(false)} />}

    </div>
  );
};

export default ScreenForm;
