import { observer } from 'mobx-react-lite';
import React from 'react';
import Texts from 'react-sdk/Texts';

import AppState, { SCREENS } from '../../js/AppState';
import Quiz from '../../js/Quiz';
import Personnage from '../Personnage/Personnage';
import Baseline from '../shared/Baseline/Baseline';
import Header from '../shared/Header/Header';
import './ScreenHome.css';



const ScreenHome = () => {

  function next() {
    AppState.showBriefing()
  }




  return (
    <div className="ScreenHome">

      <Header showLogo />

      <div className="ScreenHome-content">
        <div className="hero">
          <strong dangerouslySetInnerHTML={{__html: Texts.get("home_message1")}} />
          <p dangerouslySetInnerHTML={{__html: Texts.get("home_message2")}} />
        </div>

        <div className="ScreenHome-content-bottom">
          <div className='button-container'>
            <div>
              <button onClick={next}>
                {Quiz.hasSave() ? Texts.get("home_button_reprise") : Texts.get("home_button")}</button>
              <a className='mentions-legales' href="https://www.succubus.fr/mentions-legales/" target='_blank'>{Texts.get("home_mentions")}</a>
            </div>
          </div>

          <Personnage idleCharacter="JC" />

          <Baseline />
        </div>
      </div>
    </div>
  );
};

export default  observer(ScreenHome)
