import React, { useState } from 'react'
import Texts from 'react-sdk/Texts';
import Personnage from '../Personnage/Personnage';
import Baseline from '../shared/Baseline/Baseline';
import Header from '../shared/Header/Header';

import './ScreenEnd.css'

const ScreenEnd = () => {
  const [showPerso, setShowPerso] = useState(true);


  return (
    <div className="ScreenEnd">
      <Header showLogo />
      <div className="ScreenEnd-content">
        <div className="hero" dangerouslySetInnerHTML={{__html: Texts.get("end_message")}} />

        <div className="ScreenEnd-content-bottom">
          <div className="message" dangerouslySetInnerHTML={{__html: Texts.get("end_message_2")}} />
        </div>

        {showPerso && <Personnage sequenceId="end" onSequenceEnd={() => setShowPerso(false)} />}

        <Baseline />
      </div>
    </div>
  );
};

export default ScreenEnd;
