import { makeAutoObservable, toJS } from "mobx";
import DataLoading from 'react-sdk/DataLoading';
import Personnages from "react-sdk/Personnage/Personnages";
import Dialogues from 'react-sdk/Personnage/Dialogues'
import Texts from "react-sdk/Texts";
import VertxApi from "react-sdk/Vertx/VertxApi";
import Quiz from "./Quiz";
import Notifications from "react-sdk/Notifications/Notifications";


function getUrlVar(name) {
  var url = new URL(window.location.href);
  return url.searchParams.get(name);
}


export const SCREENS = {
  LOADING : "LOADING",
  HOME : "HOME",
  LOGIN : "LOGIN",
  CHAT : "CHAT",
  QUIZ : "QUIZ",
  BRIEFING : "BRIEFING",
  INTERLUDE : "INTERLUDE",
  PERSO : "PERSO",
  FORM : "FORM",
  END : "END"
}
export const POPUPS = {
  REGLEMENT : "REGLEMENT"
}



class AppState {
  debugMode = false
  language = "fr"
  screen = SCREENS.LOADING
  openid = null
  vertxApi = null

  dialogues = null

  screenPersonnageInfos = {
    children: null,
    sequenceId: null,
    onSequenceEnd: null
  }

  constructor() {
    makeAutoObservable(this)

    this.debugMode = getUrlVar("debug")
    this.vertxApi = new VertxApi(window.CONFIG.vertx, window.CONFIG.keycloak, window.CONFIG.LocalStorageKey)

    Personnages.waitEngineInit()
    .then(() =>
      DataLoading.getAllFiles(window.CONFIG.requiredFiles, window.CONFIG.root)
     )
    .then(files => {
      this.dialogues = new Dialogues(files.Speech)
      Personnages.init(files.characters)
      Texts.init(files.texts, this.language)
      Quiz.init(files.quiz)
    })
    .then( () => Personnages.loadAll() )
    .then( () => this.vertxApi.init() )
    .then(() => {
      // si on n'a pas été reconnecté à notre profil, on se connecte en anonymous
      if(!this.vertxApi.currentUser) {
        return this.vertxApi.anonymousAutoconnect()
      }
    })
    .then(() => this.setScreen(SCREENS.HOME))
    .catch(err => {
      Notifications.error(Texts.get("error_start_app") + " (" + err + ")")
    })
  }

  launchQuiz() {

    this.vertxApi.meetingGetWithCode(window.CONFIG.meetingCode)
    .then( meeting => {
      // meeting._id += "_ababwa"
      return this.vertxApi.meetingConnect(meeting)
    })
    .then( () => {
      console.log('connected to meeting', this.vertxApi.currentMeeting._id)
      Quiz.start()
    })
    .catch(err => {
      Notifications.error(Texts.get("error_launch_quiz") + " (" + err + ")")
    })
  }
  setScreenPerso(children, sequenceId, onSequenceEnd) {
    this.screenPersonnageInfos = { children, sequenceId, onSequenceEnd}
    this.setScreen(SCREENS.PERSO)
  }

  showBriefing() {
    if(Quiz.hasSave()) {
      this.launchQuiz()
    } else {
      if(this.debugMode){ this.launchQuiz(); return;}
      this.setScreenPerso(null, "briefing", () => this.launchQuiz())
    }
  }


  setScreen(id) { this.screen = id }

  setLanguage(l) {
    this.language = l
    Texts.setLanguage(l)
  }

}


export default new AppState()
