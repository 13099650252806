import { observer } from 'mobx-react-lite';
import React from 'react'
import Notifications from 'react-sdk/Notifications/Notifications';

import './Notifications.css'

const Notifs = () => {

  return (
    <div className="Notifications">
      {Notifications.list.entries(n => {
        return (
        <div className={"notif " + n.type} key={n.id} onClick={() => n.close()}>
          {n.message}
        </div>)
      })}

    </div>
  );
};

export default observer(Notifs)
