import { computed, makeObservable, observable } from 'mobx';
import SequencesManager, { SequencesFile } from 'react-sdk/SequencesManager';
import { pickRandom } from 'react-sdk/utils';
import AppState, { SCREENS } from './AppState';


export const RESULT = {
  CORRECT: "CORRECT",
  WRONG: "WRONG",
  TIMEOUT: "TIMEOUT"
}

class Quiz {
  lastpickedfeedback = null
  score = 0
  current_question = 1

  nb_timeouts = 0

  file = null
  sequence = null
  finished = false

  constructor() {
    makeObservable(this, {
      score: observable,
      current_question: observable,
      finished: observable,

      question_total: computed,
      stars: computed
    })
  }
  init(data) {
    // instancier un sequence manager
    this.file = SequencesFile.createFromDataFile("quiz", data)
    SequencesManager.addFile(this.file)

  }


  filterSequence() {

    let save = localStorage.getItem(this.sequenceSaveLSKey)

    let seq_ids_to_keep = []
    if(save) {
      // console.log("save", save)
      console.log("reload de la séquence")

      seq_ids_to_keep = JSON.parse(save)
    }
    else {
      // je crée un tableau intermédiaire avec les categs et les sequencesIds
      let categs_seqIds = this.file.elements
      .filter(e => e.Type === "choice_title")
      .map(e => ({
        category: e.raw.category,
        SequenceId: e.SequenceId,
      }))

      // je crée un array des categories, sans doublons
      let categs = categs_seqIds.reduce((acc, t) => {
        if(!acc.includes(t.category)) acc.push(t.category)
        return acc
      }, [])

      // pour chaque categ, on pioche 1 question
      // on renvoie sa sequenceId
      seq_ids_to_keep = categs.map(c => {
        let avail = categs_seqIds.filter(t => t.category === c)
        let rand = pickRandom(avail)
        return rand.SequenceId
      })

      localStorage.setItem(this.sequenceSaveLSKey, JSON.stringify(seq_ids_to_keep))
      console.log("save de la séquence")
    }

    // je refiltre la sequence en ne gardant que ces sequences_ids
    this.file.elements = this.file.elements.filter(e => seq_ids_to_keep.includes(e.SequenceId))

    // ici on set les goto !!! Un peu l'arrache

    let nextQuestionSequenceId = null
    for(let i = this.file.elements.length - 1; i >=0; i--) {
      let e = this.file.elements[i]
      if(e.Type === "choice_title") {
        e.Goto = nextQuestionSequenceId
        e.choices.forEach(c => c.Goto =  nextQuestionSequenceId)

        nextQuestionSequenceId = e.id
      }
    }






  }

  start() {
    this.filterSequence()

    this.resetScore()
    this.resetCurrentQuestion()
    this.finished = false
    this.sequence = SequencesManager.playSequence("quiz")

    let save = localStorage.getItem(this.scoresSaveLSKey)
    if(save) {
      save = JSON.parse(save)
      // console.log('chargement à la question numéro', save)



      this.nb_timeouts = save.nb_timeouts
      this.score = save.score

      if(save.current_question > this.question_total) {
        this.finishQuiz()
        return
      }


      for(let i = 0; i < save.current_question; i++ ){
        this.sequenceNext(true) // 1 fois apres le feedback du perso
      }

      this.current_question = save.current_question
    }

    AppState.setScreen(SCREENS.QUIZ)



  }

  hasSave() {
    return localStorage.getItem(this.scoresSaveLSKey) && localStorage.getItem(this.sequenceSaveLSKey)
  }

  sequenceNext(isReplay) {

    if(!this.sequence) return
    if(this.finished) {

      this.finishQuiz()
      return
    }


    this.sequence.next()
    this.incrementCurrentQuestion()

    if(!isReplay) this.saveProgress()

    // on fait le hasNext juste apres pour savoir en avance si on est en finished ou pas
    if(!this.sequence.hasNext()) {
      this.finished = true
    }

    // console.log("sequenceNext", this.current_question)
  }

  finishQuiz() {
    AppState.setScreen(SCREENS.FORM)
  }



  pickFeedbackSequenceId(result) {
    let id

    if(result === RESULT.TIMEOUT) {
      id = "timeout_" + this.nb_timeouts
    }
    else {
      id = `question${this.current_question}_`
      id += result === RESULT.CORRECT ? "win" : "loose"
    }
    return id
  }

  pickInterludeSequenceId() {

    let id = "interlude"
    if(this.current_question === 5) id += "1_"
    if(this.current_question === 8) id += "2_"

    id += this.score
    return id

  }


  saveProgress(forNextQuestion) {
    let save = {
      score: this.score,
      current_question: forNextQuestion ? this.current_question + 1 : this.current_question,
      nb_timeouts: this.nb_timeouts
    }
    // console.log("saving", save)
    localStorage.setItem(this.scoresSaveLSKey, JSON.stringify(save))

  }

  deleteSave() {
    localStorage.removeItem(this.scoresSaveLSKey)
    localStorage.removeItem(this.sequenceSaveLSKey)
  }

  pickStarsSequenceId() {
    let id = "star_" + this.stars
    return id
  }

  get stars() {
    if(this.score <= 3) return 1
    if(this.score <= 7) return 2
    return 3
  }
  get question_total() {
    let f = SequencesManager.findFile("quiz")

    return f.elements.filter(e => e.Type === "choice_title").length
  }

  get sequenceSaveLSKey() {
    const {LocalStorageKey} = window.CONFIG
    return LocalStorageKey + "_sequence"
  }
  get scoresSaveLSKey() {
    const {LocalStorageKey} = window.CONFIG
    return LocalStorageKey + "_scores"
  }

  incrementCurrentQuestion(increment = 1) {
    this.current_question += increment
  }
  resetCurrentQuestion(value = 1) {
    this.current_question = value
  }

  incrementScore(increment = 1) {
    this.score += increment
  }
  resetScore(value = 0) {
    this.score = value
  }





}

export default new Quiz()