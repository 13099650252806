import { observer } from 'mobx-react-lite';
import React from 'react'
import Quiz from '../../../js/Quiz';

import './StarsHeader.css'


const Star = ({lit = false}) => {
  return (
    <svg className={"Star" + (lit ? " lit" : "")} version="1.1" x="0px" y="0px" width="80" height="80" viewBox="0 0 40 40" enableBackground="new 0 0 40 40" >
      <g id="55152624_Shape_5_xA0_Clipping_Path_1_">
        <g>
          <path d="M37.936,15.729c-0.154-0.457-0.582-0.765-1.063-0.766l-11.966-0.016l-3.844-11.41C20.91,3.081,20.482,2.773,20,2.773    c-0.481,0-0.909,0.308-1.063,0.764l-3.843,11.41L3.127,14.963c-0.481,0.001-0.909,0.309-1.062,0.766    c-0.153,0.456,0.001,0.959,0.385,1.25l9.612,7.301L8.464,35.769c-0.144,0.461,0.021,0.96,0.411,1.243    c0.197,0.144,0.428,0.215,0.66,0.215c0.228,0,0.456-0.069,0.65-0.207L20,30.047l9.815,6.972c0.194,0.138,0.421,0.207,0.649,0.207    c0.231,0,0.464-0.071,0.66-0.215c0.39-0.283,0.555-0.784,0.41-1.243l-3.598-11.488l9.611-7.301 C37.933,16.688,38.088,16.185,37.936,15.729z"></path>
        </g>
      </g>
    </svg>
  )
}

const StarsHeader = () => {
  let stars = []
  for(let i=0; i<3;i++) {
    stars.push(<Star lit={i < Quiz.stars} key={i} />)
  }

  return (
    <div className="StarsHeader">{stars}</div>
  );
};

export default observer(StarsHeader)
