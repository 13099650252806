import React, { useEffect, useState } from 'react'
import Loader from '../../shared/Loader/Loader';

import './QuizImage.css'

const QuizImage = ({src}) => {

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(false)
  }, [src])


  function handleLoad() {
    setLoaded(true)
  }


  return (
    <div className={"QuizImage" + (loaded ? " loaded" : "" )}>
      <img src={src} alt="image" onLoad={handleLoad}  />
      {!loaded && <div className="image-loader"><Loader /></div>}
    </div>
  );
};

export default QuizImage;
