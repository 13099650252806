import { makeAutoObservable } from "mobx"

/**
 * permet de gérer une stack de popups
 * soit utiliser en singleton, soit instancier
 *
 *
 */


export class PopupsManager {

  datas = new Map()
  vertxApi = null

  constructor() {

    this.closeAll = this.closeAll.bind(this)

    makeAutoObservable(this)
  }

  // NOTE: c'est optionnel d'appeler cette méthode
  init(vertxApi) {
    this.vertxApi = vertxApi
  }


  open(popup_id, data=null) {
    console.log("open", popup_id)
    if(!this.isOpened(popup_id)) {
      if(this.vertxApi) this.vertxApi.analytics.navigationEnter("popup." + popup_id)
      // this.popups.add(popup_id)
      this.datas.set(popup_id, data)
    }
  }

  close(popup_id) {
    if(this.isOpened(popup_id)) {
      if(this.vertxApi) this.vertxApi.analytics.navigationLeave("popup." + this.popup)
      // this.popups.delete(popup_id)
      this.datas.delete(popup_id)
    }
  }

  getData(popup_id) {
    return this.datas.get(popup_id)
  }


  isOpened(popup_id) { return this.datas.has(popup_id) }
  get someOpened() { return this.datas.size > 0 }

  closeAll() { this.datas.clear() }
}

export default new PopupsManager()