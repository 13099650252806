import { computed, makeAutoObservable, observable } from "mobx"

const GMS = window.gms

const VC = GMS.VertxClient

class VertxApi {

  client = null
  localstorageKey = null

  anonymousCredentials = null

  currentUser = null // objet user identique à celui de la mongo
  userCustomDatas = null

  currentMeetingUsers = []

  tokens = {
    sessionToken: null,
    refreshToken: null
  }

  keycloakConfig = null


  /**
   * @param {{ Url: string, Port: string, Universe: string, App: string }} vertxConfig La config Vertx
   * @param {{ Url: string, Realm: string, ClientId: string, RedirectUri: string }} keycloakConfig La config Keycloak
   * @param {string} localstorageKey Une chaine de caractère servant de préfixe à la sauvegarde locale
   */
  constructor(vertxConfig, keycloakConfig, localstorageKey) {

    let {Url, Port, Universe, App } = vertxConfig

    this.client = VC.CreateVertxClient(Url, Port);
    this.localstorageKey = localstorageKey


    this.universe = Universe
    this.app = App

    this.keycloakConfig = keycloakConfig


    // on essaie de récupérer les datas du local storage
    this.getDataFromLocalStorage()

    makeAutoObservable(this, {
      currentUser: observable,
      currentMeetingUsers: observable,
      userCustomDatas: observable,

      isAnonymous: computed
    })
  }

  init() {
    // si on avait coché "remember_session", on essaie de se reco en automatique
    if(this.remember_session) {
      return this.keycloakAutoconnect()
    }

  }

  // USER --> login, infos /////////////////////////////////////////
  get keycloakUrl() {
    const { Url, Realm } = this.keycloakConfig
    return `${Url}/auth/realms/${Realm}`
  }

  get keycloakEndpoints() {
    return {
      authorization: `${this.keycloakUrl}/protocol/openid-connect/auth`,
      token: `${this.keycloakUrl}/protocol/openid-connect/token`,
      registration: `${this.keycloakUrl}/clients-registrations/openid-connect`,
      userInfo: `${this.keycloakUrl}/protocol/openid-connect/userinfo`,
      userProfile: `${this.keycloakUrl}/account`,
      logout: `${this.keycloakUrl}/protocol/openid-connect/logout`,
      forgotPass: `${this.keycloakUrl}/login-actions/reset-credentials`
    }
  }

  get isAnonymous() {
    return this.currentUser && this.currentUser.Login.includes("anonymous_")
  }

  /**
   * Permet de se connecter en tant qu'anonymous, en créant un compte anon si nécessaire
   * @returns
   */
  anonymousAutoconnect() {
    let prev_credentials = this.anonymousCredentials

    if(prev_credentials) {
      try {
        const {UserLogin, UserPassword} = prev_credentials

        return this.login(UserLogin, UserPassword)
        .then(() => {
          console.log("USING PREVIOUS ANONYMOUS USER")
        })
      }
      catch(err) {

      }
    }

    console.log("NEW ANONYMOUS USER")

    return new Promise( (resolve, reject) => {
      this.client.UserAnonymousAdd(this.universe, (success, msg, data) => {
        if (success) {
          this.login(data.UserLogin, data.UserPassword)
          .then(() => {
            this.anonymousCredentials = {
              UserLogin: data.UserLogin,
              UserPassword: data.UserPassword
            }

            this.saveDataInLocalStorage()

            resolve(data)
          })
          .catch(err => {
            reject(err)
          })
        } else {
          reject(msg)
        }
      })
    })
  }

  keycloakAutoconnect() {
    // 1/ on essaie de se connecter avec le sessionToken
    console.log("keycloakAutoconnect")
    return this.loginWithSessionToken()
    .then(null, () => this.loginWithRefreshToken())
    .catch(err => {
      console.log("keycloakAutoconnect n'a pas abouti")
    })
  }

  /**
   * Permet de se connecter au serveur. Fonctionne avec les universe Keycloak ou sans Keycloak.
   * On essaie de l'utiliser UNIQUEMENT pour les universe NON gérés pas Keycloak, ou pour les anonymous, car on ne peut pas
   * gérer les différents tokens de Keycloak avec ça
   * @param {string} login
   * @param {string} password
   * @returns
   */
  login(login, password) {
    return new Promise( (resolve, reject) => {
      this.client.UserConnect(this.universe, this.app, login, password, (success, msg, data) => {
        if(success) {
          resolve()
        }
        else {
          console.log("login error", msg)
          reject(msg)
        }
      })
    })
    .then(()=> this.setCurrentUser())
  }


  loginWithCredentials(login, password, remember_session = false) {
    console.log("connect_with_credentials START")

    let { ClientId } = this.keycloakConfig


    return fetch(this.keycloakEndpoints.token, {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: `client_id=${ClientId}&grant_type=password&username=${login}&password=${password}`
    })
    .then(res => res.json())
    .then(data => {

      if(data.error) {
        throw data
      }

      // save tokens !
      this.tokens.refreshToken = data.refresh_token
      this.remember_session = remember_session

      this.saveDataInLocalStorage()

      console.log("connect_with_credentials OK")

      return this.loginWithAccessToken(data.access_token)

    })

  }

  /**
   * Nous connecte au SGMS via l'access_token fourni par KC
   * @param {string} kc_access_token c'est l'access token de keycloak
   * @returns {Promise}
   */
  loginWithAccessToken(kc_access_token) {
    return new Promise((resolve, reject) => {
      this.client.UserConnectNonEncryptedToken(this.universe, this.app, kc_access_token, (success, msg, data) => {
        if(success) {
          resolve()
        } else {
          console.log("Failed to Connect", msg, data)
          reject(msg)
        }
      })
    })
    .then(() => this.setCurrentUser())
  }

  /**
   * Permet de se connecter via le token d'une session précédente. Attention, c'est le token Vertx, pas Keycloak
   * @returns {Promise}
   */
  loginWithSessionToken() {
    const {sessionToken} = this.tokens


    if(!sessionToken) return Promise.reject()
    return new Promise( (resolve, reject) => {
      this.client.UserConnectWithGmsToken(this.universe, this.app, sessionToken, (success, msg, data) => {
        if(success) { resolve() }
        else {
          console.log("loginWithSessionToken", msg)
          reject(msg)
        }
      })
    })
    .then(() => this.setCurrentUser())
  }

  /**
   * Permet de redemander à KC un access token à partir du refresh token qu a stocké
   * Avec cet access token, on peut se connecter sur le vertx
   * @returns {Promise}
   */
  loginWithRefreshToken() {
    // ici on va demander à KC un nouvel access_token à partir du refreshToken
    const {refreshToken} = this.tokens
    const {ClientId} = this.keycloakConfig

    if(!refreshToken) return Promise.reject()

    return fetch(this.keycloakEndpoints.token, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: `grant_type=refresh_token&refresh_token=${refreshToken}&client_id=${ClientId}`
    })
    .then(res => res.json())
    .then(data => {
      if(data.error) {
        throw data.error
      }
      else {

        // maj du refresh token
        this.tokens.refreshToken = data.refresh_token

        this.saveDataInLocalStorage()

        // ici il faut redemander un sessiontoken avec l'access token qu'on ne sauvegarde pas
        return this.loginWithAccessToken(data.access_token)
      }
    })

  }


  loginWithExternalProvider(provider_id) {
    const createAuthUrl = (provider_id) => {
      let url = new URL(this.keycloakEndpoints.authorization)
      url.searchParams.append("redirect_uri", this.keycloakConfig.RedirectUri)
      url.searchParams.append("client_id", this.keycloakConfig.ClientId)
      url.searchParams.append("scope", "openid profile email")
      url.searchParams.append("response_type", "code")
      url.searchParams.append("prompt", "consent")
      url.searchParams.append("kc_idp_hint", provider_id)
      return url.href
    }

    const codeToToken = (code, redirectUri) => {
      return fetch(this.keycloakEndpoints.token, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `grant_type=authorization_code&client_id=${this.keycloakConfig.ClientId}&code=${code}&redirect_uri=${redirectUri}`
      }).then(res => res.json())
    }


    return new Promise( (resolve, reject) => {
      const url = createAuthUrl(provider_id)

      // 1/ on ouvre une popup de connexion
      const popup = window.open(url, "-", "menubar=no, status=no, scrollbars=no, width=600, height=600")

      let itv = setInterval(() => {
        if(popup.closed) {
          clearInterval(itv)
          reject({error: 'closed_by_user'})
        }
      }, 200);

      const handleConnectionEvent = (e) => {
        if (e.origin !== window.origin) return
        clearInterval(itv)
        popup.close() // fermeture de la popup

        if(e.data.code) {
          codeToToken(e.data.code, this.keycloakConfig.RedirectUri)
          .then(data => {
            if(!data.access_token) return reject(data)

            this.tokens.refreshToken = data.refresh_token
            this.remember_session = true
            this.saveDataInLocalStorage()

            return this.loginWithAccessToken(data.access_token)
          })
          .then(() => resolve() )
          .catch(err => reject(err))
        } else {
          reject("code_not_found")
        }


      }

      // 2/ on attend les retours
      window.addEventListener("message", handleConnectionEvent, {once: true})
    })


  }

  logout() {
    return new Promise( (resolve, reject) => {
      this.client.UserDisconnect((success, msg, data) => {
        // NOTE(g) parfois y'a des erreurs alors que ca marche en fait alors on considère que ca marche tt le temps 😬
        this.currentUser = null

        this.remember_session = false
        this.saveDataInLocalStorage()

        resolve()
      })
    })
  }

  /**
   * Crée le User dans le serveur, et dans KC aussi si l'universe a KC pour idp
   * @param {string} login une adresse email mais pas obligatoire
   * @param {string} password le password NON hashé
   * @param {string} pseudo
   * @param {string} lastname
   * @param {string} firstname
   * @returns {Promise}
   */
  userCreate(login, password, pseudo, lastname="", firstname="") {
    const user = {
      Login: login,
      Password: password,
      Mail: login,
      Pseudo: pseudo,
      Universe: this.universe,
      LastName: lastname,
      FirstName: firstname
    }
    return new Promise( (resolve, reject) => {
      this.client.UserAdd(user, (success, msg, data) => {
        if(success) resolve()
        else reject(msg)
      })
    })
  }


  userResetPasswordStep1(email) {
    return new Promise( (resolve, reject) => {
      this.client.UserResetPasswordSendMail(this.universe, email, (success, msg, data) => {
        if(success) {
          resolve(data)
        }
        else {
          reject(msg)
        }
      })
    })
  }

  // reset password, step 2, envoyer les infos avec le code reçu dans le mail du serveur
  userResetPasswordStep2(email, password, magic_key) {
    return new Promise( (resolve, reject) => {
      this.client.UserResetPassword(this.universe, email, password, magic_key, (success, msg, data) => {
        if(success) {
          resolve(data)
        }
        else {
          reject(msg)
        }
      })
    })
  }

  userUpdateInfo(Mail, Pseudo, LastName, FirstName, customDatas = null) {
    let infos = { Mail, Pseudo, LastName, FirstName }

    return new Promise( (resolve, reject) => {
      this.client.UserUpdateInfo(infos, (success, msg, data) => {
        if(success) {
          resolve()
        }
        else {
          reject("UserUpdateInfo" + msg)
        }
      })

    })
  }

  userUpdateCustomDatas(datas) {
    return new Promise( (resolve, reject) => {
      this.client.UserUpdateCustomData(JSON.stringify(datas), (success, msg, data) => {
        if(success) {
          this.userCustomDatas = datas
          resolve()
        }
        else {
          reject(msg)
        }
      })

    })
  }


  /**
   * Fait une requête sur le user en base, et l'écrit dans this.currentUser
   * @returns
   */
  setCurrentUser() {
    return new Promise( (resolve, reject) => {
      this.client.UserGetInfo((success, msg, data) => {
        if (success) {
          this.currentUser = VC.GetJsonObject(data);

          // ne pas enregistrer si le user est un anonymous
          if(!this.isAnonymous) this.tokens.sessionToken = this.client.getSessionToken()

          resolve()
        } else {
          reject(msg)
        }
      })
    })
  }

  saveDataInLocalStorage() {
    let data = {
      tokens: this.tokens,
      remember_session: this.remember_session,
      anonymousCredentials: this.anonymousCredentials
    }
    localStorage.setItem(this.localstorageKey, JSON.stringify(data))
  }
  getDataFromLocalStorage() {
    try {
      let d = JSON.parse( localStorage.getItem(this.localstorageKey) )
      if(d) {
        this.tokens = d.tokens
        this.remember_session = d.remember_session
        this.anonymousCredentials = d.anonymousCredentials
      }
    }
    catch(err) {
      console.log("error parsing LS data", err)
    }
  }

  // <-- USER /////////////////////////////////////////


  // --> MEETINGS /////////////////////////////////////////

  meetingGetWithCode(code) {
    return new Promise( (resolve, reject) => {
      if(!this.currentUser) {
        reject("Not logged in")
        return
      }

      this.client.MeetingAddUserWithCode(code, this.currentUser.Login, (success, msg, data) => {
        if(success) {
          this.meetingGetInfos(data.MeetingID)
          .then(meeting => resolve(meeting))
          .catch(err => reject(err))
        }
        else reject(msg)
      })
    })
  }

  meetingGetInfos(meetingId) {
    return new Promise( (resolve, reject) => {
      this.client.MeetingGetInfos(meetingId, (success, msg, data) => {
        if(success) {
          let meetingInfos =  VC.GetJsonObject(data)

          if(!meetingInfos.MeetingCode) {
            this.meetingGetCode(meetingId)
            .then(codeData => {
              meetingInfos = {...meetingInfos, ...codeData}
              resolve(meetingInfos)
            })
            .catch(err => reject(err))
          } else {
            resolve(meetingInfos)
          }
        }
        else {
          reject(msg)
        }
      })
    })
  }


  meetingGetCode(meetingId) {
    return new Promise( (resolve, reject) => {
      this.client.MeetingGetCode(meetingId, (success, msg, codeData) => {
        if(success) {
          resolve(codeData)
        } else {
          reject(msg)
        }
      })
    })
  }

  meetingConnect(meeting) {
    return new Promise( (resolve, reject) => {
      this.client.UserMeetingConnect(meeting._id, (success, msg, data) => {

        if (success) {
          this.currentMeeting = meeting
          this.currentMeetingUsers = []
          resolve()
        }
        else reject(msg)
      })
    })
  }

  meetingSendScores(scores) {
    return this.meetingSendMessage("Score", scores)
  }

  meetingSendMessage(name, data = {}, toAdminOnly=false) {
    return new Promise( (resolve, reject) => {
      if(!this.currentMeeting) {
        reject("Not logged in")
        return
      }

      let meetingId = this.currentMeeting._id
      const msg = { Name: name, Data: data }

      this.client.UserMeetingSendToAll(meetingId, JSON.stringify(msg), toAdminOnly, (success, msg, data) => {
        if(success) {
          resolve()
        }
        else {
          reject("meetingSendMessage " + msg)
        }
      })

    })
  }

  meetingGetConnectedUsersAndScores(){
    return new Promise( (resolve, reject) => {
      if(!this.currentMeeting) {
        reject("Not connected to a meeting")
        return
      }

      let meetingId = this.currentMeeting._id

      this.client.UserMeetingGetConnected(meetingId, (success, msg, usersData) => {
        if(success) {

          this.client.UserMeetingGetScores(meetingId, (success, msg, scoresData) => {
            if(success) {
              let meetingUsers = VC.GetJsonObject(usersData) // [{ userid: userpseudo }]
              // console.log("usersdata", meetingUsers)
              let newMeetingUsers = []
              let scores = VC.GetJsonObject(scoresData)
              for(let id in meetingUsers) {
                let prev = this.currentMeetingUsers.find(u => u.id === id)


                newMeetingUsers.push({
                  id,
                  pseudo: meetingUsers[id],
                  scores: scores[id],
                  status: prev && prev.status
                })
              }
              this.currentMeetingUsers = newMeetingUsers
              resolve(newMeetingUsers)
            }
            else {
              reject(msg)
            }
          })
        } else {
          reject(msg)
        }
      })
    })
  }


  meetingGetFields(fields) {
    return new Promise( (resolve, reject) => {
      if(!this.currentMeeting) {
        reject("Not connected to a meeting")
        return
      }

      let meetingId = this.currentMeeting._id

      this.client.UserMeetingGetFields(meetingId, fields, (success, msg, data) => {
        if(success) {
          resolve(VC.GetJsonObject(data))
        }
        else {
          reject(msg)

        }
      })

    })
  }


  // <-- MEETINGS /////////////////////////////////////////




}

export default VertxApi