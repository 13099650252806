import { observer } from 'mobx-react-lite';
import React from 'react'
import Texts from 'react-sdk/Texts';
import Quiz, { RESULT } from '../../../js/Quiz';

import './QuizDebrief.css'

const QuizDebrief = ({answer_result, text, onNext}) => {

  return (
    <div className="QuizDebrief">
      <div className={(answer_result === RESULT.CORRECT ? " correct" : " incorrect")}>
        {text}
      </div>

      {!Quiz.finished && <button onClick={onNext}>{Texts.get("quiz_button_next")}</button>}
      {Quiz.finished && <button onClick={onNext}>{Texts.get("quiz_button_finish")}</button>}
    </div>
  );
};

export default observer(QuizDebrief)
