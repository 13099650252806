import React, { useEffect, useState } from 'react'

import './QuizDuration.css'


let timer = null

const getDuration = (q) => {
  if(!q) return 100
  return q.raw.duration ? q.raw.duration * 10 : 100
}

const QuizDuration = ({q, answered, onTimeElapsed}) => {

  const [duration, setDuration] = useState(null);

  useEffect(() => {
    if(!q) return

    setDuration(getDuration(q))


    timer = setInterval(()=> setDuration(d => d-1) , 100)

    return () => {
      clearInterval(timer)
      timer = null
    }
  }, [q]);

  useEffect(() => {
    if(duration !== null && duration <= 0) {
      clearInterval(timer)
      onTimeElapsed()
    }
  }, [duration]);

  useEffect(() => {
   if(answered) {
    clearInterval(timer)
   }
  }, [answered])

  let percent = (duration * 100) / getDuration(q)

  return (
    <div className="QuizDuration">

      <div className={"QuizDuration-bar" + (duration < 20 ? " urgent" : "")} style={{width: percent + "%" }}>
        {duration > 10 && <span>{(duration / 10).toFixed(1) }</span>}
      </div>

    </div>
  );
};

export default QuizDuration;
