import React, { useEffect, useState } from 'react'
import Texts from 'react-sdk/Texts';
import { useSpring, animated } from 'react-spring';
import Quiz from '../../../js/Quiz';

import './QuizQuestionCount.css'

const QuizQuestionCount = () => {


  const [s, setS] = useState({transform: "translateY(100%)", opacity: 0});

  useEffect(() => {
    setS({transform: "translateY(0%)", opacity: 1});

    let to = setTimeout(() => {
      setS({transform: "translateY(-100%)", opacity: 0})
    }, window.CONFIG.timeouts.questionNum - 500)

    return () => {
      clearTimeout(to)
    }

  } , [])



  return (
    <div className="QuizQuestionCount" >
      <animated.div className="QuizQuestionCount-content" style={s}>
        {Texts.get("quiz_count")}
        <span>{Quiz.current_question.toString().padStart(2, "0")}/{Quiz.question_total.toString().padStart(2, "0")}</span>
      </animated.div>
    </div>
  );
};

export default QuizQuestionCount;
