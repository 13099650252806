import { observer } from 'mobx-react-lite'
import React, { useEffect, useRef, useState } from 'react'
import Personnages from 'react-sdk/Personnage/Personnages'

import AppState from '../../js/AppState'


import './Personnage.css'
import SpeechDisplayer from './SpeechDisplayer/SpeechDisplayer'



const Personnage = ({sequenceId, onSequenceEnd = null, idleCharacter}) => {

  const [subtitle, setSubtitle] = useState(null);
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    if(sequenceId) {
      AppState.dialogues.playSequence(sequenceId, onSequenceEnd, text => setSubtitle(text) )
    }

    if(!sequenceId && idleCharacter) {
      let chara = Personnages.get(idleCharacter)
      chara.startIdle()

    }
  }, [sequenceId, idleCharacter]);

  useEffect(() => {
    setTimeout(() => {
      setVisible(true)

    }, 50)
  }, []);

  let currentDial = AppState.dialogues.current


  function skip() {
    if(currentDial) {
      currentDial.skip()
    }
  }
  return (
    <div className={"Personnage " + (visible ? "visible" : "hidden")}>

      <div className="canvas-ctnr">

        <SpeechDisplayer character={currentDial ? currentDial.personnage : Personnages.get(idleCharacter)} />


      </div>

      {currentDial && subtitle &&
      <div className="dial-overlay" onClick={skip}>
        <div className="dial">
          <div className="dial-character">{currentDial && currentDial.data.Character}</div>
          <div className="dial-text">{subtitle}</div>
        </div>
      </div>}
    </div>
  )
}

export default observer(Personnage)