import React, { useEffect, useRef } from 'react'
import confetti from 'canvas-confetti'
import './Confettis.css'
import { randomRange } from 'react-sdk/utils';

const Confettis = ({success}) => {
  const canvasRef = useRef(null)

  function party() {
    var count = 200;
    var defaults = { origin: { y: 0.8 } }

    let myconfetti = confetti.create(canvasRef.current, { resize: true })
    // myconfetti();

    function fire(particleRatio, opts) {
      myconfetti(Object.assign({}, defaults, opts, {
        particleCount: Math.floor(count * particleRatio)
      }));
    }

    fire(0.25, { spread: 50, startVelocity: 55, })
    fire(0.2, { spread: 120 })
    fire(0.35, { spread: 200, decay: 0.91, scalar: 0.8 })
    fire(0.1, { spread: 220, startVelocity: 25, decay: 0.92, scalar: 1.2 })
    fire(0.1, { spread: 220, startVelocity: 45 })
  }

  function snow() {

    let myconfetti = confetti.create(canvasRef.current, { resize: true })

    var duration = 5 * 1000;
    var animationEnd = Date.now() + duration;
    var skew = 1;

    function randomInRange(min, max) { return Math.random() * (max - min) + min; }





    function frame() {
      var timeLeft = animationEnd - Date.now();
      var ticks = Math.max(200, 500 * (timeLeft / duration));
      skew = Math.max(0.8, skew - 0.001);

      for(var i = 0; i < 2; i++) {
        myconfetti({
          particleCount: 1,
          startVelocity: randomInRange(0, 0.3),
          ticks: ticks,
          origin: {
            x: Math.random(),
            // since particles fall down, skew start toward the top
            y: (Math.random() * skew) - 0.2
          },
          colors: ['#c1c6ff'],
          shapes: ['circle', 'square'],
          gravity: randomInRange(5, 6),
          scalar: randomInRange(0.4, 1),
          drift: randomInRange(-0.4, 0.4)
        });
      }

      if (timeLeft > 0) {
        requestAnimationFrame(frame);
      }
    }

    requestAnimationFrame(frame);
  }

  useEffect(() => {
    if(success) party()
    else snow()
  }, []);


  return (
    <div className="Confettis">
      {/* <button onClick={snow}>snow</button> */}
      <canvas ref={canvasRef}></canvas>
    </div>
  );
};

export default Confettis;
