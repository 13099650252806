import React from 'react'
import PopupsManager from 'react-sdk/PopupsManager';

import './Popup.css'

const Popup = ({data}) => {

  function handleClose() {
    PopupsManager.closeAll()
  }
  return (
    <div className="Popup-overlay" onClick={handleClose}>

      <div className="Popup" onClick={e => e.stopPropagation()}>
        <div className="Popup-close" onClick={handleClose}>&times;</div>
        <h1>{data.title}</h1>
        <div className="Popup-content" dangerouslySetInnerHTML={{__html: data.content}} />
      </div>
    </div>
  );
};

export default Popup;
