import { observer } from 'mobx-react-lite';
import React from 'react';
import './Header.css';

import logo from "../../../images/logo.png"

const Header = ({children, showLogo}) => {


  return (
    <div className="Header">
      <div className="Header-content">
        {showLogo && <img src={logo} alt="logo succubus" />}
        {children}
      </div>


    </div>
  );
};

export default  observer(Header)
