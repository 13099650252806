import { observer } from 'mobx-react-lite';
import React from 'react'
import AppState from '../../js/AppState';
import Personnage from '../Personnage/Personnage';
import Header from '../shared/Header/Header';

import './ScreenPerso.css'

// un écran simple qui affiche une sequence de dial, et qui passse à autre chose


const ScreenPerso = () => {

  let {children, sequenceId, onSequenceEnd } = AppState.screenPersonnageInfos

  return (
    <div className={"ScreenPerso " + sequenceId}>

      <Header>{children}</Header>

      <Personnage sequenceId={sequenceId} onSequenceEnd={onSequenceEnd} />

    </div>
  );
};

export default observer(ScreenPerso)
